<template>
  <ConfigPage>
    <ConfigItem title="Companhias">
      <v-card outlined :loading="loading">
        <v-list>
          <v-list-item
            v-for="insurer in insurers"
            :key="insurer.id"
            link
            class="primary--text"
            :to="{
              name: 'configs.insurers_portal.show',
              params: {
                id: insurer.id,
              },
            }"
          >
            <v-list-item-content>
              <v-list-item-title v-text="insurer.name"> </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </ConfigItem>
  </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";

import { index } from "@/services/configs/insurers_portal";

export default {
  mixins: [],

  components: {
    ConfigPage,
    ConfigItem,
  },

  props: {},

  data() {
    return {
      insurers: [],
      loading: false,
    };
  },

  methods: {
    async loadItems() {
      this.loading = true;
      this.insurers = await index();
      this.loading = false;
    },
  },

  computed: {},

  created() {
    this.loadItems();
  },
};
</script>
